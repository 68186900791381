import _u from "wtc-utility-helpers";
import {
  default as ElementController,
  ExecuteControllers,
} from "wtc-controller-element";

class Nav extends ElementController {
  constructor(element, options = {}) {
    super(element);
    this.nComBar = document.querySelector(".nclood-nav__container");
    this.currentPos = 0;

    this.sticky();
  }

  // Make nav sticky when scrolling
  sticky() {
    let minH = this.nComBar.offsetHeight,
      yPos = window.pageYOffset;

    // if(this.currentPos !== yPos) {
    //   this.currentPos = yPos;
    //   _u.addClass('nav-moving',document.body);
    // } else {
    //   _u.removeClass('nav-moving',document.body);
    // }

    if (yPos > minH) {
      _u.addClass("nav-sticky", document.body);
    } else {
      _u.removeClass("nav-sticky", document.body);
    }

    window.requestAnimationFrame(this.sticky.bind(this));
  }
}

function hamburgerStyles() {
  const hamburgerMenu = document.querySelector("input[name=kirby-nav__toggle]");
  const navBar = document.getElementById("nav-kirby");

  hamburgerMenu.addEventListener("change", function () {
    if (this.checked) {
      navBar.classList.add("hamburgerOpen");
    } else {
      navBar.classList.remove("hamburgerOpen");
    }
  });
}

ExecuteControllers.registerController(Nav, "Nav");

export default Nav;

hamburgerStyles();
