import _u from 'wtc-utility-helpers';
import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';
import Modal from 'wtc-modal-view';

class Home extends ElementController {
  constructor (element) {
    super(element);

    // let modalBtn = document.querySelector('.modal-btn');
    // let modalVideo = document.querySelectorAll('[data-video]');
    // let modalRetailer = document.querySelectorAll('[data-retailer]');

    // const cardcontainer = document.querySelector(".game__card__hidden > .content");
    // const cards = document.querySelector(".games__game-cards");
    // const stars = document.querySelector(".starburst");

    this.audioToggle = this.audioToggle.bind(this);

    this.init()
  }
  init() {
    this.audioBtn = document.querySelector('.audio-btn');
    this.audioStatus = document.querySelector('.audio-btn__status');
    this.audioLiveRegion = document.querySelector('.audio-btn__live-region');
    this.enableText = 'Enable background music on this site.';
    this.disableText = 'Disable background music on this site.';
    this.audioBtn.addEventListener('click', this.audioToggle);
  }

  audioToggle(event) {
    let audioBtn = document.querySelector('.audio-btn');
    let audioEl = document.querySelector('#page-audio');
    if (audioEl.paused) {
      audioBtn.classList.add('is-playing');
      audioEl.play();
      this.audioLiveRegion.innerHTML = this.disableText;
      nclood.Metrics.trackLink({
        eVars: { 39: 'sound on'},
        events: [53]
      }, event);
    } else {
      audioBtn.classList.remove('is-playing');
      audioEl.pause();
      this.audioLiveRegion.innerHTML = this.enableText;
      nclood.Metrics.trackLink({
        eVars: { 39: 'sound off' },
        events: [53]
      }, event);
    }
  }
}

ExecuteControllers.registerController(Home, 'Home');

export default Home;
