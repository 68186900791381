import CONSTS from '../config';
import { default as ElementController, ExecuteControllers }  from 'wtc-controller-element';
import { TweenMax, TimelineLite, Power2, Power3, Power4, Elastic, Expo } from "gsap";
import vector from 'wtc-vector';
import _u from 'wtc-utility-helpers';
import Preloader from 'wtc-utility-preloader';
import { rmGetState } from './ReduceMotion.js';

class Particle  {
  constructor() {
    this.element = document.createElement('div');
    this.element.className = 'hero__particlesystem__particle';

    this.position = new vector(1, 0);
    this.scale = 0;
    this.remove = false;
    this._opacity = 1;
  }

  update() {
    TweenMax.set(this.element, {x: this.position.x, y: this.position.y, scale: this.scale, opacity: this.opacity});

    if(this.scale >= this.scaleToRemoveAt) this.remove = true;
  }

  get scaleToRemoveAt() {
    return 2.5;
  }

  get opacity() {
    return this._opacity;
  }
  set opacity(value) {
    if(!isNaN(value)) {
      if(value > 1) {
        value = 1;
      } else if(value < 0) {
        value = 0;
      }
      this._opacity = value;
    }
  }
  get angle() {
    return this.position.angle;
  }
  set angle(angle) {
    if(!isNaN(angle)) this.position.angle = angle;
  }
  get length() {
    return this.position.length;
  }
  set length(length) {
    if(!isNaN(length)) this.position.length = length;
  }
}

class ParticleSystem {
  constructor(element) {
    this.element = element;

    this.running = true;
    this.spawning = false;
    this.lastSpawnTime = 0;

    this.stars = [];
    this.numStarsToSpawn = 2; // 5
    this.timescale = 0.3; // 0.7
    this.timeBetweenSpawns = 100; // 500
    this.randomness = 1;
    this.baseDistanceBetweenParticles = 150; // 150
    this.angularMomentum = -0.015; // - 0.02
    this.polarMomentum = 3; // 4
    this.zoomMomentum = 0.012; // 0.012

    window.particlesystem = this;

    this.animate = this.animate.bind(this)

    requestAnimationFrame(this.animate);
  }

  animate(delta) {
    if(this.running == true) {
      this.updateParticles();

      if(this.spawning === true) {
        this.lastSpawnTime = delta;
        this.spawning = false;
        let randomInitialAngle = Math.random() * Math.PI * 2;

        this.tidyParticles();

        for(let i = 0; i < this.numStarsToSpawn; i++) {
          setTimeout(()=> {
            this.addParticle(randomInitialAngle);
          }, this.baseDistanceBetweenParticles / this.timescale * i * (1.0 + Math.random() * this.randomness));
        }
      } else {
        if(delta - this.lastSpawnTime > this.timeBetweenSpawns / this.timescale) {
          this.spawning = true;
        }
      }
    }
    requestAnimationFrame(this.animate);
  }

  addParticle(randomInitialAngle = 0) {
    let particle = new Particle();
    particle.angle = randomInitialAngle;
    this.stars.push(particle);
    this.element.appendChild(particle.element);
  }

  updateParticles() {
    _u.forEachNode(this.stars, (index, star) => {
      star.scale += this.zoomMomentum * this.timescale;
      star.angle += this.angularMomentum * this.timescale * (1 + star.scale);
      star.length += this.polarMomentum * this.timescale * (1 + star.scale);
      star.opacity = star.scaleToRemoveAt - star.scale;
      star.update();
    });
  }

  tidyParticles() {
    for(let i = this.stars.length -1; i >= 0; i--) {
      let star = this.stars[i];
      if(star.remove === true) {
        this.element.removeChild(star.element);
        this.stars.splice(i, 1);
      }
    }
  }
}

class KirbyHeader extends ElementController {
  constructor(element) {
    super(element);

    const removeIntroFrames = () => {
      let heroWrap = document.querySelector('.hero-wrapper');
      let frames = heroWrap.querySelectorAll('.hero__frame');

      for (let frame of frames) {
        heroWrap.removeChild(frame);
      }
    };

    this.tl = new TimelineLite( { paused:true, onComplete: removeIntroFrames } );
    this.preloader = new Preloader({debug: false});
    this.isPreloading = false;
    this.particlesystem = new ParticleSystem(element.querySelector('.hero__particlesystem'));
    this.rmToggleStars = this.rmToggleStars.bind(this);
    this.toggleReducedMotion = this.toggleReducedMotion.bind(this);

    // reduced motion stars
    if (!rmGetState()) this.toggleParticleSystem();
    this.rmToggleStars();

    let allImages = this.element.querySelectorAll('img');
    if(allImages.length > 0) {
      for(let item of allImages) {
        this.preloader.add(item.src, 'image');
      }
    }
    this.init();

    window.addEventListener('reduce-motion', this.rmToggleStars);
    window.addEventListener('reduce-motion', this.toggleReducedMotion);
  }

  init() {
    window.addEventListener('keydown', (e)=> {
      window._event = e;
      if ((e.keyCode == 101 || e.keyCode == 69) && e.shiftKey) {
        localStorage.setItem('lastRun', null);
        window.location.reload(false);
      }
    });

    let lastRunTime = localStorage.getItem('lastRun');

    if(!isNaN(lastRunTime) && (Date.now() - Number(lastRunTime)) / 1000 / 60 / 60 < 24 ) {
      this.skipIntro = true;
    } else {
      this.skipIntro = false;
      localStorage.setItem('lastRun', Date.now());
    }
    this.initPreloader();
  }

  initPreloader() {
    if(!this.isPreloading) {
      this.isPreloading = true;
      this.preloader.load(this.onAllLoaded.bind(this));
    }
  }

  onAllLoaded() {
    let frames = this.element.querySelectorAll('.hero__frame');
    let heroElement = this.element;
    let splatElement = this.element.querySelector('.hero-splat');
    let finalImgs = heroElement.querySelectorAll('.hero__image-group img');
    let finalLogo = heroElement.querySelector('h1 span.img');
    let finalText = heroElement.querySelectorAll('.hero__content .transition');

    setTimeout(function() {
      document.querySelector('.hero').classList.add('is-loaded');
    }, 500)


    if(!this.skipIntro) {

      this.setupIntroFrames(frames);

      //splat
      if (rmGetState()) {
        this.tl.set(heroElement.querySelector('.hero__final'),{ opacity:1 });
        this.tl.addLabel('splat');
      } else {
        this.tl.set(splatElement,{ display:"block"});
        this.tl.to(splatElement.querySelector('.hero-splat__img--incoming'), 1.0, { scale:1.2, opacity:1, ease:Power2.easeIn })
        this.tl.set(splatElement.querySelector('.hero-splat__img--incoming'),{ opacity:0 });
        this.tl.set(splatElement.querySelector('.hero-splat__img--squished'),{ opacity:1 });
        this.tl.set(heroElement.querySelector('.hero__final'),{ opacity:1 });
        this.tl.addLabel('splat',"+=0.35")
        this.tl.to(splatElement.querySelector('.hero-splat__img--squished'), 2.7, { yPercent:100 , ease:Power2.easeOut,opacity:0},'splat');
      }

      // final frame
      if (rmGetState()) {
        this.tl.staggerFrom(finalImgs, 0.5, {opacity: 0}, 0.2, 'splat+=1');
        this.tl.from(finalLogo, .6, {opacity: 0}, '-=1.5');
      } else {
        this.tl.staggerFrom(finalImgs, 0.5, { ease: Power4.easeInOut, left: "0%", top: "-20%", scale: 0, opacity: 0, rotation: (-20 + Math.random() * 40), clearProps:'left,top,rotation' },0.2,'splat');
        this.tl.from(finalLogo, .6, { ease: Power4.easeIn, left: "70%", scale: 0, opacity: 0, rotation: (360 + Math.random() * 40) }, "-=1.5");
      }

      this.tl.staggerFrom(finalText, .5, { opacity: 0 },0.2,'-=1.4');
      this.tl.call(function(){
        this.tl.set(splatElement,{ display:"none"});
      }.bind(this));

    } else {
      // if intro is skipped

      this.tl.set(heroElement.querySelector('.hero__final'),{ opacity:1 });
      this.tl.addLabel('fade','+=.3')
      this.tl.to(heroElement.querySelectorAll('.hero__bg:not(.hero__bg--final)'),0.4, { opacity:0 }, 'fade');
      this.tl.addLabel('start','+=.6')

      if (rmGetState()) {
        this.tl.staggerFrom(finalImgs, 0.5, {opacity: 0}, 0.2, 'start');
        this.tl.from(finalLogo, .6, {opacity: 0}, '-=1.5');
      } else {
        this.tl.staggerFrom(finalImgs, 0.5, { ease: Power4.easeInOut, left: "0%", top: "-20%", scale: 0, opacity: 0, rotation: (-20 + Math.random() * 40), clearProps:'left,top,rotation' },0.2,'start');
        this.tl.from(finalLogo, .6, { ease: Power4.easeIn, left: "70%", scale: 0, opacity: 0, rotation: (360 + Math.random() * 40) }, "-=1.5");
      }

      this.tl.staggerFrom(finalText, .5, { opacity: 0 },0.2,'-=.4');

    }

    this.tl.play();

  }

  toggleParticleSystem() {
    let heroHeight = this.element.offsetHeight,
      yPos = window.pageYOffset;
      if(yPos > heroHeight) {
        this.particlesystem.running = false;
      } else if (rmGetState()) {
        this.particlesystem.running = false;
      } else {
        this.particlesystem.running = true;
      }

      window.requestAnimationFrame(this.toggleParticleSystem.bind(this));
  }

  // toggles star movement based on reduced motion preference
  rmToggleStars() {
    if (rmGetState()) this.particlesystem.running = false;
    else this.particlesystem.running = true;
  }

  setupIntroFrames(frameNodeset) {
    _u.forEachNode( frameNodeset, (index, frame ) => {
      let img = frame.querySelector(".hero__frame__img");
      let txt = frame.querySelector(".hero__frame__txt");
      let bg = this.element.querySelector("." + frame.dataset.name);
      let imgTweenObj = {};
      let txtTweenObj = {};

      if (rmGetState()) {

        imgTweenObj = {
          opacity: 1
        }
        
        txtTweenObj = {
          opacity: 1
        }

      } else {

        imgTweenObj = {
          xPercent: img.dataset.fromX || -100,
          yPercent: img.dataset.fromY || 100,
          scale: img.dataset.fromScale || 1,
          ease : Power4.easeInOut
        }

        txtTweenObj = {
          xPercent: txt.dataset.fromX || 0,
          opacity: txt.dataset.fromOpacity || 1,
          ease: Expo.easeOut
        }

      }

      this.tl.addLabel('frame-start-' + index);
      this.tl.to(frame,0.2,{ opacity: 1 }, 'frame-start-'+ index);
      this.tl.from(img, 1.2, imgTweenObj,'-=.1');
      this.tl.from(txt, 0.6, txtTweenObj,'-=.5');
      this.tl.addLabel('frame-end-' + index,"+=.6");
      this.tl.to([frame,bg],0.4,{ opacity: 0}, 'frame-end-'+index);
    });
  }

  toggleReducedMotion() {
    if (this.tl.isActive()) {
      localStorage.removeItem('lastRun');
      window.location.reload();
    }
  }
}



ExecuteControllers.registerController(KirbyHeader, 'KirbyHeader');

export { KirbyHeader };
