import { default as ElementController, ExecuteControllers }  from 'wtc-controller-element';
import { TweenMax, TimelineLite, Power2, Power4, Elastic } from "gsap";
import vector from 'wtc-vector';
import _u from 'wtc-utility-helpers';

class KirbyPageHeader extends ElementController {
  constructor(element) {
    super(element);
    document.addEventListener('DOMContentLoaded', function() {
      _u.addClass('loaded',element);
    });


  }

  // toggleParticleSystem() {
  //   let heroHeight = this.element.offsetHeight,
  //     yPos = window.pageYOffset;
  //     if(yPos > heroHeight) {
  //       this.particlesystem.running = false;
  //     } else {
  //       this.particlesystem.running = true;
  //     }

  //     window.requestAnimationFrame(this.toggleParticleSystem.bind(this));
  // }


  setupImages(imageNodeset) {
    _u.forEachNode( imageNodeset, (index, img) => {
       this.tl.set(img,{ opacity:0, xPercent:-100} );
    });
    this.tl.pause();

    _u.forEachNode( imageNodeset, (index, img) => {
      this.tl.to(img,0.3,{ opacity:1, xPercent:0, ease:Power3.easeIn })




      // let resetAnimation = function(property, delay) {
      //   let scaleFactor = 1.0;
      //   if(img.dataset.factor && !isNaN(img.dataset.factor)) {
      //     scaleFactor = Number(img.dataset.factor);
      //   }

      //   let tweenObj = { ease: Power2.easeInOut, onComplete: ()=> { resetAnimation(property) } };
      //   let length = 2 + Math.random() * 2;

      //   switch (property) {
      //     case 'rotation':
      //       tweenObj.rotation = (-10 + Math.random() * 20) * scaleFactor;
      //       break;
      //     case 'x':
      //       tweenObj.x = (-30 + Math.random() * 60) * scaleFactor;
      //       break;
      //     case 'y':
      //       tweenObj.y = (-40 + Math.random() * 80) * scaleFactor;
      //       break;
      //     case 'scale':
      //       length = 1.5 + Math.random() * 0.2;
      //       tweenObj.scale = (0.95 + Math.random() * 0.1 * scaleFactor);
      //       break;
      //   }

      //   TweenMax.to(img, length, tweenObj);
      // }

      // resetAnimation('rotation', Math.random());
      // resetAnimation('x', Math.random());
      // resetAnimation('y', Math.random());
      // resetAnimation('scale', Math.random());
    });
  }
}



ExecuteControllers.registerController(KirbyPageHeader, 'KirbyPageHeader');

export { KirbyPageHeader };
