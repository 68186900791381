import _u from 'wtc-utility-helpers';
import {Viewport} from 'wtc-controller-viewports';
import {default as ElementController, ExecuteControllers}  from "wtc-controller-element";
import Modal from 'wtc-modal-view';
import CONFIG from '../config';
import Tracking from '../Nintendo/Tracking';


Date.prototype.isLeapYear = function() {
    var year = this.getFullYear();
    if((year & 3) != 0) return false;
    return ((year % 100) != 0 || (year % 400) == 0);
};

// Get Day of Year
Date.prototype.getDOY = function() {
    var dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
    var mn = this.getMonth();
    var dn = this.getDate();
    var dayOfYear = dayCount[mn] + dn;
    if(mn > 1 && this.isLeapYear()) dayOfYear++;
    return dayOfYear;
};

// Returns the ISO week of the date.
Date.prototype.getWeek = function() {
  var date = new Date(this.getTime());
   date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
}


Date.prototype.formatDate = function() {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  var day = this.getDate();
  var monthIndex = this.getMonth();
  var year = this.getFullYear();

  return monthNames[monthIndex] + ' ' + day + ' ' + year;
}



class Facts extends Viewport {
  constructor (element) {
    super(element);

    let panelClass = '.panel';
    let panelContentClass = '.panel__content';
    let panelContentImgClass = '.panel__image img';
    let panelContentTitleClass = '.panel__header h4';


    this.data_url = 'assets/data/facts.json';
    this.data = {};



    this.panel = element.querySelector(panelClass);
    this.panel_content = element.querySelector(panelContentClass);
    this.panel_title =  element.querySelector(panelContentTitleClass);
    this.panel_image =  element.querySelector(panelContentImgClass);


    this.today = new Date();
    this.currentDayNumber =  this.today.getDOY();
    this.currentWeekNumber = this.today.getWeek();

    this.setCurrentDate();

    // Retrieve jSON data file
    this.getData();
  }
  setCurrentDate() {
    let date = this.element.querySelector('time');
    if(date) {
      date.innerHTML = this.today.formatDate();
    }

  }
  getData() {
    let basePath = CONFIG.CONST.DOCROOT;
    let dataUrl =  basePath + this.data_url;
    let parsed, xhr;

    xhr = new XMLHttpRequest();
    xhr.open('GET', dataUrl, true);
    xhr.callback = this.onLoadJSON.bind(this);
    xhr.onload = function (e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          xhr.callback.call(this,xhr);
        } else {
          console.error(xhr.statusText);
        }
      }
    }.bind(this);
    xhr.onerror = function (e) {
      console.error(xhr.statusText);
    };

    xhr.send(null);
  }

  onLoadJSON(request) {
    let parsed = JSON.parse(request.responseText);
    this.format = parsed.format;
    this.data = parsed.data;

    this.updateFactPanel();
  }

  updateFactPanel() {
    //add one to currentWeek to offset array.
    const basePath = CONFIG.CONST.DOCROOT = 'assets/img/facts/';

    let factNumber = this.format === 'week' ? this.currentWeekNumber - 1 : this.currentDayNumber;
    let fact = this.data[factNumber];
    let image = fact.img;

    let srcset = [];
    for(let img of image){
      srcset.push(`${basePath}${img.src} ${img.size}`);
    }

    this.panel_title.innerText = fact.name;

    this.panel_image.src = basePath + image[0].src;
    this.panel_image.setAttribute('srcset',srcset.join(','));
    this.panel_image.setAttribute('alt',fact.name);

    this.panel_content.innerHTML = `<p>${fact.fact}</p>`;

    _u.addClass('is-active',this.panel);
  }




}

ExecuteControllers.registerController(Facts, 'Facts');

export default Facts;
