import { default as ElementController, ExecuteControllers }  from 'wtc-controller-element';
import {Viewport} from 'wtc-controller-viewports';
import Breakpoints from 'wtc-utility-breakpoint';
import { TweenMax, TimelineLite, Power2, Power4, Elastic } from "gsap";
import vector from 'wtc-vector';
import _u from 'wtc-utility-helpers';

class Games extends Viewport {
  constructor (element, options = {}) {
    super(element);
    this.toggleSort = this.toggleSort.bind(this);
    this.loadAll = this.loadAll.bind(this);
    this.init.bind(this);

    this.games = document.querySelectorAll('.games__item');
    this.titleEls = document.querySelectorAll('.games__title');
    this.toggleEl = document.querySelector('.game-sort__toggle');
    this.liveRegionSort = document.querySelector('.game-sort__live-region');
    this.liveRegionLoad = document.querySelector('.games__live-region');
    this.sortTextDescending = 'Game titles currently sorted by date, newest to oldest.';
    this.sortTextAscending = 'Game titles currently sorted by date, oldest to newest.';
    this.loadMoreText = `${this.games.length - 8} game titles loaded.`;
    this.loadLessText = `${this.games.length - 8} game titles hidden.`;
    this.parentEl = document.querySelector('.games');
    this.loadAllEl = document.querySelector('.games__load-all .button');
    this.elems = [];
    this.sortStatus = false;
    this.showStatus = false;

    window.addEventListener('resize',this.debounceResize.bind(this));
    this.loadAllEl.addEventListener('click', this.loadAll);    
    this.toggleEl.addEventListener('click', (e) => {
      this.toggleSort(e);
    });

    this.init();
  }

  sort(elements) {
    let elems = [];
    for( var i = 0; i < elements.length; ++i ) {
      elems.push(elements[i]);
    }
    return elems.reverse();
  }

  toggleSort(e) {
    this.sortStatus = !this.sortStatus;
    this.toggleEl.classList.toggle('sort-oldest');

    let collection = document.querySelectorAll('.games__item');
    let newOrder = this.sort(collection);

    let hide = (delay, f) => {
      f();
      return new Promise((resolve) => {
        setTimeout(resolve, delay)
      });
    };

    hide(350, () => {
      this.parentEl.classList.add('is-hidden');
    }).then(() => {
      // empty list
      while(this.parentEl.hasChildNodes()) {
        this.parentEl.removeChild(this.parentEl.lastChild);
      }
      // refill list with newly ordered elements
      for( var i = 0; i < newOrder.length; ++i ) {
        this.parentEl.appendChild(newOrder[i]);
      }
      // show list
      this.parentEl.classList.remove('is-hidden')
    });

    this.updateSortLiveRegion();

    let omniString;
    this.sortStatus ? omniString = 'oldest' : omniString = 'newest';
    
    nclood.Metrics.trackLink({
      eVars: { 39: `button:${omniString}`},
      events: [53]
    }, e);
  }

  updateLoadLiveRegion() {
    const showThenHide = (showDuration, hideFn) => {
      hideFn();
      return new Promise(resolve => setTimeout(resolve, showDuration));
    }

    if (this.parentEl.classList.contains('is-load-all')) {
      this.liveRegionLoad.innerHTML = this.loadMoreText;
    } else {
      this.liveRegionLoad.innerHTML = this.loadLessText;
    }

    // Only "aria-unhide" this long enough for it to be announced. We don't want to be able to traverse this paragraph.
    showThenHide(500, () => {
      this.liveRegionLoad.removeAttribute('aria-hidden');
    }).then(() => {
      this.liveRegionLoad.setAttribute('aria-hidden', 'true');
    });
  }

  updateSortLiveRegion() {
    if (this.sortStatus) {
      this.liveRegionSort.innerHTML = this.sortTextAscending;
    } else {
      this.liveRegionSort.innerHTML = this.sortTextDescending;
    }
  }

  loadAll(e) {
    this.sortStatus = !this.sortStatus;
    e.preventDefault();

    let hide = () => {
      this.loadAllEl.childNodes[0].textContent = 'Show more';
    }

    let show = () => {
      this.loadAllEl.childNodes[0].textContent = 'Show less';
    }

    this.parentEl.classList.contains('is-load-all') ? hide() : show()    
    this.parentEl.classList.toggle('is-load-all');

    this.manageFocus();
    this.updateLoadLiveRegion();

    let omniString;
    this.sortStatus ? omniString = 'show more' : omniString = 'show less';
    
    nclood.Metrics.trackLink({
      eVars: { 39: `button:${omniString}`},
      events: [53]
    }, e);
  }

  manageFocus() {
    let itemToFocus = this.parentEl.querySelectorAll('.games__item')[8],
      childFocusable = itemToFocus.querySelector('a, button');

    if (childFocusable) {
      childFocusable.focus();
    } else {
      itemToFocus.setAttribute('tabindex', -1);
      itemToFocus.focus();
    }
  }

  // resize

  debounceResize() {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(this.resizeHandler.bind(this),50);
  }

  resizeHandler() {
    if(Breakpoints.current > 1) {
      let maxHeight = 0;
      for(let i = 0; i < this.titleEls.length; i++) {
        this.titleEls[i].style.height = "auto";
        if(maxHeight < this.titleEls[i].offsetHeight) {
          maxHeight = this.titleEls[i].offsetHeight;
        }
      }
      for(let i = 0; i < this.titleEls.length; i++) {
        this.titleEls[i].style.height = `${maxHeight}px`;
      }
    } else {
      for(let i = 0; i < this.titleEls.length; i++) {
        this.titleEls[i].style.height = 'auto';
      }
    }
  }

  init() {
    this.resizeHandler();
  }
}



ExecuteControllers.registerController(Games, 'Games');

export { Games };
