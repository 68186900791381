// Browser Support
// import 'babel-polyfill';
import Matches from './App/Polyfills/Matches';

// Base
import _u from 'wtc-utility-helpers';
import CONFIG from './App/config';
import Utils from './App/Utils/All';
import {ExecuteControllers} from 'wtc-controller-element';
import { rmInit } from "./App/Controllers/ReduceMotion.js";

// Single Controllers
import Modal from "wtc-modal-view"
import {Viewport} from 'wtc-controller-viewports';
import Gallery from 'wtc-gallery-component';
import Tracking from './App/Nintendo/Tracking';
import { BarfyStars, Particle, ACTIONS } from 'wtc-barfystars';
import { KirbyHeader } from './App/Controllers/header';
import { KirbyPageHeader } from './App/Controllers/pageHeader';
import EqualHeight from './App/Controllers/equalHeight';
import Nav from './App/Controllers/nav';
import Facts from './App/Controllers/facts';
import Games from './App/Controllers/games';
import renderNews from "./App/news/news-article";
import newsPageInit from "./App/news/news";

// Page Controllers
import Home from './App/Pages/Home';

// Reduced Motion
rmInit();

// Auto update footer copyright year
document.querySelector('.footer-main .cpdate').html = new Date().getFullYear();

// Start tracking.
let trackOpts = Utils.getTrackingOpts(document.body);
trackOpts.devMode = (CONFIG.CONST.ENV === 'DEV' || CONFIG.CONST.ENV === 'LOCAL') ? true : false,
Tracking.init(trackOpts);

// Instanciate all controllers
ExecuteControllers.instanciateAll();

// Video buttons
Utils.initVideos()


// News grid init
const pageSlug = document.body.dataset.pageOmniture;
if (pageSlug === "home") renderNews(3, 1, false); 
else if (pageSlug === "news-and-videos") newsPageInit();

