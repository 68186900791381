import _u from 'wtc-utility-helpers';
import Modal from 'wtc-modal-view';
import CONFIG from '../config';

let helpers = {}

helpers.initVideos = function () {
  let hash = Modal.hash;
  if (hash) {
    let el = document.querySelector(`[data-video-hash="${hash}"]`);

    if (el) {
      let id = el.getAttribute('data-video');

      if (id) {
        setTimeout(function() {
          Modal.openVideo({
            videoId: id,
            hash: hash,
            chromeless: false
          })
        },250)

      }
    }
  }

  try {
    nclood.mount('nclood-video')
  }
  catch (e) {

  }

  _u.forEachNode(document.querySelectorAll('[data-video]'), (index, element)=>{
    let el = element;
    let id = element.getAttribute('data-video');
    el.removeAttribute('data-video');
    el.addEventListener('click', (e)=> {
      let element = el;

      //querySelector video
      //if exist then pause video

      let el = e.currentTarget;
      let hash = el.getAttribute('data-video-hash');

      Modal.openVideo({
        videoId: id,
        hash: hash,
        chromeless: false
      });

      const modal = Modal.instance;
      
      modal.onClose = function() {
        el.focus()
        modal.onOpen = null;
      }
      
      Modal.open()
      
      modal.modalClose.focus()

      let closeModalOmniture = function(e) {
        e.target.removeEventListener(e.type, closeModalOmniture);
        nclood.Metrics.trackLink({
          eVars: { 39: 'button:close'},
          events: [53]
        }, e);
      }
      window.addEventListener('wtc-modal-close', closeModalOmniture)


    });

  });
}

helpers.getTrackingOpts = function (body) {
  // Set up all our tracking
  let base = (body) ? body : document.getElementById('wrap');
  let pagename = base.getAttribute('data-page-omniture') || null;
  let ishome = base.getAttribute('data-page') == 'home' ? true : false;
  let lang = CONFIG.CONST.LANGUAGE;
  let referrer = /(\?|\&)r=([a-zA-Z0-9\.]+)/.exec(window.location.href);

  if (referrer && referrer.length === 3) {
    referrer = referrer[2];
  } else {
    referrer = null;
  }

  return {
    trackerProps: {
      suite: CONFIG.CONST.SUITE[lang.toUpperCase()],
      siteName: CONFIG.CONST.SITENAME,
      pageName: pagename,
      platform: CONFIG.CONST.PLATFORM,
    },
    pageTrackOpts: {
      vanity: referrer,
      productCode: CONFIG.CONST.PRODUCTCODE,
      productName: CONFIG.CONST.PRODUCTNAME,
      productId: CONFIG.CONST.PRODUCTID,
      gameTitle: CONFIG.CONST.PRODUCTNAME,
      genre: CONFIG.CONST.GENRE,
      publisher: CONFIG.CONST.PUBLISHER,
      isHome: ishome
    }
  };
}

export default helpers;
