import renderNews from "./news-article";


const newsPageInit = () => {
  const NEWS_LIMIT = 6,
    newsWrapper = document.querySelector("[data-news]");

  if (!newsWrapper) return;

  const loadMoreBtn = document.querySelector(".load-more"),
    liveRegion = document.querySelector("[data-live-loaded]"),
    liveRegionText = liveRegion.dataset.liveLoaded;

  let page = 1,
    lastQuantity = 0,
    checked = false;

  const loadMoreNews = async page => {
    const numArticles = await renderNews(NEWS_LIMIT, page, true);

    if (numArticles > 0) {
      handleLoadMoreFocus();
      handleLoadMoreAnnounce();
    } else {
      handleLoadMoreFocus(true);
    }
  };

  const onLoadMore = () => {
    page++;
    loadMoreNews(page);
    toggleLoadMore();
  };

  const handleLoadMoreFocus = (focusLastItem = false) => {
    const newsItems = newsWrapper.querySelectorAll(".news-item");
    const numNewsItems = newsItems.length;

    if (focusLastItem) {
      newsItems[numNewsItems - 1].querySelector("a").focus();
    } else {
      // shift focus to the "newest" article in the grid:
      newsItems[lastQuantity].querySelector("a").focus();
    }
  };

  const handleLoadMoreAnnounce = () => {
    // update the aria live region:
    const numNewArticles =
      newsWrapper.querySelectorAll("article").length - lastQuantity;
    liveRegion.innerHTML = liveRegionText.replace("${X}", numNewArticles);
  };

  const checkArticles = () => {
    const numArticles = newsWrapper.querySelectorAll("article").length;

    if (!checked) {
      if (numArticles === NEWS_LIMIT) {
        loadMoreBtn.classList.remove("load-more--hidden");
      }

      checked = true;
    }

    if (numArticles < lastQuantity + NEWS_LIMIT) {
      loadMoreBtn.classList.add("load-more--hidden");
    } else {
      lastQuantity = numArticles;
    }
  };

  const toggleLoadMore = () => {
    if (newsWrapper.dataset.news == "") {
      // if no news has loaded yet…
      const initialLoadTimer = setInterval(() => {
        if (newsWrapper.dataset.news === "loaded") {
          checkArticles();
          clearInterval(initialLoadTimer);
        }
      }, 400);
    } else {
      // …else, this is a "load more" action
      const newBatchTimer = setInterval(() => {
        if (newsWrapper.dataset.loadMore) {
          checkArticles();
          clearInterval(newBatchTimer);
        }
      }, 200);
    }
  };

  // add event listener
  loadMoreBtn.addEventListener("click", onLoadMore);

  // initialize
  renderNews(NEWS_LIMIT, 1, true); // renderNews(limit, page, useModal)
  toggleLoadMore();
};

export default newsPageInit;
